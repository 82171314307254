import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() { }

  toggle(state: boolean): void {
    this.showLoader.next(state);
  }

  getLoader(): Observable<boolean> {
    return this.showLoader.asObservable();
  }
}